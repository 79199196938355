<template>
  <column-layout class="page" sticky-sides noRight>
    <!-- Left -->
    <template v-slot:left>
      <AdminMenu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget">
        <label class="label">
          {{ $t("reports.reports") }}
        </label>

        <div class="card pa">
          <div class="stat-boxes">
            <StatBox
              v-for="(box, i) in boxes"
              :key="i"
              :label="box.label"
              :value="box.value"
              :loading="!!$loading('admin/statsByType')"
            />
          </div>

          <HashtagsTable class="mt-10" />
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu.vue";
import HashtagsTable from "@/components/admin/hashtags/HashtagsTable.vue";
import StatBox from "@/components/admin/StatBox.vue";

export default {
  metaInfo: { title: "Reports" },

  components: { AdminMenu, HashtagsTable, StatBox },

  data: () => ({
    stats: {
      total_hashtags: null,
      trending_hashtags: null,
      hot_hashtags_this_month: null,
    },
  }),

  computed: {
    boxes() {
      return [
        {
          label: this.$t("hashtags.totalHashtags"),
          value: this.stats.total_hashtags,
        },
        {
          label: this.$t("hashtags.trendingHashtags"),
          value: this.stats.trending_hashtags?.length,
        },
        {
          label: this.$t("hashtags.hotHashtags"),
          value: this.stats.hot_hashtags_this_month?.length,
        },
      ];
    },
  },

  created() {
    this.fetchStats();
  },

  methods: {
    fetchStats() {
      this.$store
        .dispatch("admin/statsByType", "hashtag")
        .then((stats) => {
          this.stats = stats;
        })
        .catch(() => {
          this.$toast.error("Error while fetching stats for reports.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
