<template>
  <v-data-table
    :items="data"
    :headers="headers"
    :items-per-page.sync="perPage"
    :page.sync="page"
    :server-items-length="total"
    :loading="$loading('hashtags/fetch')"
    :footer-props="$config.vuetify.vDataTable.footerProps"
    @update:page="fetchData"
    @update:items-per-page="fetchData"
  >
    <template v-slot:top>
      <div class="d-flex mb-3">
        <SearchInput
          v-model="searchInput"
          @search="
            page = 1;
            fetchData();
          "
          class="rounded-lg"
          style="max-width: 250px"
        />
      </div>
    </template>

    <!-- Updated_at -->
    <template v-slot:item.updated_at="{ value }">
      {{ $utils.formatDatetime(value) }}
    </template>
  </v-data-table>
</template>

<script>
import SearchInput from "@/components/common/SearchInput.vue";

export default {
  components: { SearchInput },

  data() {
    return {
      searchInput: "",
      data: [],
      page: 1,
      perPage: 10,
      total: null,
      headers: [
        {
          text: this.$t("hashtags.hashtag"),
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("hashtags.mentions"),
          sortable: false,
          value: "mentions_count",
        },
        {
          text: this.$t("hashtags.trendingScore"),
          sortable: false,
          value: "trending_score",
        },
        {
          text: this.$t("hashtags.lastUsed"),
          sortable: false,
          value: "updated_at",
        },
      ],
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      const params = {
        page: this.page,
        per_page: this.perPage,
      };

      if (this.searchInput) {
        params["search[name]"] = this.searchInput;
      }

      this.$store
        .dispatch("hashtags/fetch", params)
        .then((res) => {
          this.data = res.data;
          this.total = res.meta.total;
        })
        .catch(() => {
          this.$toast.error("Error while fetching hashtags.");
        });
    },
  },
};
</script>

<style></style>
