<template>
  <v-text-field
    v-model="input"
    :placeholder="$t('common.search')"
    background-color="secondary"
    :append-icon="value ? undefined : 'mdi-magnify'"
    solo
    hide-details
    clearable
    class="search-input"
    @input="search"
  />
</template>

<script>
import debounce from "@/utils/debounce";

export default {
  name: "SearchInput",

  props: {
    value: String,
  },

  computed: {
    input: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    search: debounce(function (input) {
      this.$emit("search", input);
    }, 500),
  },
};
</script>
